// app/ClientProviders.tsx
'use client';

import { ThemeProvider } from '@/styles/ThemeProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { useAnalytics } from '@/lib/hooks/useAnalytics';
import { gtmConfig } from '@/lib/config/gtmconfig';

export default function ClientProviders({ children }) {
  const [queryClient] = useState(() => new QueryClient());
  useAnalytics(gtmConfig);

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      enableSystem
      disableTransitionOnChange
    >
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </ThemeProvider>
  );
}