import { useEffect } from 'react';

import { gtm } from '@/lib/gtm';

export const usePageView = (): void => {
  useEffect(() => {
    gtm.push({
      event: 'page_view',
      page: {
        path: window.location.pathname,
        title: document.title,
        url: window.location.href,
        referrer: document.referrer,
      },
    });
  }, []);
};
