import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CustomError } from '../errors/CustomError';
import { Participant } from '../types/validations';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isCustomError(error: CustomError): error is CustomError {
  return error instanceof CustomError && typeof error.showToClient === 'boolean';
}

// Helper function to validate email format
//export const isValidEmail = (email : string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
export const isValidEmail = (email: string): boolean => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const parseAndValidateData = (data: string): Participant[] | null => {
  const lines = data.split('\n').filter(Boolean);
  if (lines.length > 200) return null;

  const participants = lines.map((line) => {
    const [firstName, lastName, email] = line.split(',').map((field) => field.trim());
    if (!firstName || !lastName || !isValidEmail(email)) return null;
    return { firstName, lastName, email };
  });

  // Check if any participant is null (invalid data format)
  if (participants.includes(null)) return null;

  return participants as Participant[];
};

//If inviet not sent then only we can send invite
export const canSendInvite = (status: string): boolean => {
  return status === 'INVITE_NOT_SENT' || status === 'INVITE_SENT' || status === 'PENDING_RESPONSE';
};

export const toTitleCase = (str: string): string => {
  return str == "" || str == null ? "" :  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const processOrganizationLogo = (file: File, maxWidth: number, maxHeight: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate dimensions for the image
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = width * scaleFactor;
          height = height * scaleFactor;
        }

        // Resize the canvas to the new dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the image onto the canvas
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert canvas to Base64 string
        const base64String = canvas.toDataURL('image/png'); // You can use 'image/jpeg' too
        resolve(base64String);
      };

      img.onerror = () => reject(new Error('Image processing failed'));
      img.src = event.target?.result as string;
    };

    reader.onerror = () => reject(new Error('File reading failed'));
    reader.readAsDataURL(file); // Start reading the file
  });
};

export const PLAN_CONFIG = {
  Free: {
    prices: {
      monthly: 0,
      yearly: 0
    },
    limits: {
      organizations: 1,
      projects: 2,
      users: 5,
      totalParticipants: 25,
      scenariosPerProject: 3,
      customQuestions: 10,
      emailSupport: true,
      advancedAnalytics: false,
      customBranding: false,     
      prioritySupport: false,
      dedicatedAccountManager: false,
      customIntegration: false,
    },
    stripeIds: {
      monthly: process.env.NEXT_PUBLIC_STRIPE_BASIC_MONTHLY_PRICE_ID,
      yearly: process.env.NEXT_PUBLIC_STRIPE_BASIC_YEARLY_PRICE_ID
    },
    popular: false,
    description: 'For individuals and small teams',
  },
  Gold: {
    prices: {
      monthly: 79.99,
      yearly: 767.88
    },
    limits: {
      organizations: 3,
      projects: 10,
      users: 10,
      totalParticipants: 1000,
      scenariosPerProject: 5,
      customQuestions: 100,
      emailSupport: true,
      advancedAnalytics: true,
      customBranding: true,     
      prioritySupport: true,
      dedicatedAccountManager: false,
      customIntegration: false,
    },
    stripeIds: {
      monthly: process.env.NEXT_PUBLIC_STRIPE_GOLD_MONTHLY_PRICE_ID,
      yearly: process.env.NEXT_PUBLIC_STRIPE_GOLD_YEARLY_PRICE_ID
    },
    popular: true,
    description: 'For growing businesses',
  },
  Platinum: {
    prices: {
      monthly: 199.99,
      yearly: 1919.88
    },
    limits: {
      organizations: 5,
      projects: 20,
      users: 20,
      totalParticipants: 2000,
      scenariosPerProject: 10,
      customQuestions: 250,
      emailSupport: true,
      advancedAnalytics: true,
      customBranding: true,    
      prioritySupport: true,
      dedicatedAccountManager: true,
      customIntegration: true,
    },
    stripeIds: {
      monthly: process.env.NEXT_PUBLIC_STRIPE_PLATINUM_MONTHLY_PRICE_ID,
      yearly: process.env.NEXT_PUBLIC_STRIPE_PLATINUM_YEARLY_PRICE_ID
    },
    popular: false,
    description: 'For large organizations',
  }
} as const;


// Helper function to get the feature name
export const getFeatureName = (feature: keyof typeof PLAN_CONFIG['Free']['limits']): string => {
  switch (feature) {
    case 'organizations':
      return 'Organizations';
    case 'projects':
      return 'Projects';
    case 'users':
      return 'Organization Members';
    case 'totalParticipants':
      return 'Total Participants';
    case 'scenariosPerProject':
      return 'Scenarios per Project';
    case 'customQuestions':
      return 'Custom Questions';
    case 'advancedAnalytics':
      return 'Advanced Analytics';
    case 'customBranding':
      return 'Custom Branding';
    case 'emailSupport':
      return 'Email Support';
    case 'prioritySupport':
      return 'Priority Support';
    case 'dedicatedAccountManager':
      return 'Dedicated Account Manager';
    case 'customIntegration':
      return 'Custom Integration';
    default:
      return feature;
  }
};

// Helper function to get the feature description
// Helper function to get the feature description
export const getFeatureDescription = (feature: keyof typeof PLAN_CONFIG['Free']['limits']): string => {
  switch (feature) {
    case 'organizations':
      return 'Number of organizations you can create';
    case 'projects':
      return 'Number of projects you can create';
    case 'users':
      return 'Number of organization members allowed';
    case 'totalParticipants':
      return 'Total number of participants allowed';
    case 'scenariosPerProject':
      return 'Number of scenarios you can create per project';
    case 'customQuestions':
      return 'Number of custom questions you can create';
    case 'advancedAnalytics':
      return 'Access to advanced analytics, insights, and reporting features';
    case 'customBranding':
      return 'Add your own branding, logo, and colors';
    case 'emailSupport':
      return 'Get support via email within 24 hours';
    case 'prioritySupport':
      return 'Priority email and chat support with faster response times';
    case 'dedicatedAccountManager':
      return 'A dedicated account manager to help you succeed';
    case 'customIntegration':
      return 'Custom API integration support and development';
    default:
      return feature;
  }
};
// Helper functions
export const getPlanPrice = (planName: keyof typeof PLAN_CONFIG, interval: 'monthly' | 'yearly'): number => {
  return PLAN_CONFIG[planName].prices[interval];
};

export const getPlanStripeId = (planName: keyof typeof PLAN_CONFIG, interval: 'monthly' | 'yearly'): string => {
  return PLAN_CONFIG[planName].stripeIds[interval];
};

// Update the getPriceId function to use the config
export const getPriceId = (plan: string, interval: string) => {
  return getPlanStripeId(plan as keyof typeof PLAN_CONFIG, interval as 'monthly' | 'yearly');
};

export const getPlanNameAndIntervalAndPrice = (priceId: string) => {
  for (const [plan, config] of Object.entries(PLAN_CONFIG)) {
    if (priceId === config.stripeIds.monthly || priceId === config.stripeIds.yearly) {
      return { plan, interval: priceId === config.stripeIds.monthly ? 'monthly' : 'yearly', price: config.prices[priceId === config.stripeIds.monthly ? 'monthly' : 'yearly'] };
    }
  }
}

//Get the plan details
export const getPlanDetails = (priceId: string) => {
  for (const [plan, config] of Object.entries(PLAN_CONFIG)) {
    if (priceId === config.stripeIds.monthly || priceId === config.stripeIds.yearly) {
      return { plan, ...config };
    }
  }
}

// Helper function to convert a date in a specific timezone to UTC
export const convertToUtc = (date: Date, timezone: string): Date => {
  // Use toLocaleString to convert date to the specified timezone
  const options = { timeZone: timezone, hour12: false };
  const localeString = date.toLocaleString("en-US", options);

  // Parse the components of the converted date
  const parts = localeString.split(/[/,\s:]+/); // Split by `/`, `,`, `space`, or `:`
  const month = parseInt(parts[0], 10) - 1; // Months are zero-based in Date
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  const hour = parseInt(parts[3], 10);
  const minute = parseInt(parts[4], 10);
  const second = parseInt(parts[5] || "0", 10); // Handle cases without seconds

  // Return a UTC date
  return new Date(Date.UTC(year, month, day, hour, minute, second));
};
