
'use client';

import Link from 'next/link';
import { Button } from '@/components/ui/Button';
import Image from 'next/image';
import { ArrowRight } from 'lucide-react';
import HowItWorks from './HowItWorks';
import { usePageView } from '@/lib/hooks/usePageView';

const HeroScreenshot = () => {
  return (
    <div className=" mt-16 mx-8">
      <Image
        src="/static/images/marketing/screenshot_main_roommate.PNG" // Must be uppercase
        alt="App screenshot"
        className="m-auto h-[95%] lg:max-lg:w-[95%]  2xl:max-w-[72rem] p-2 border-2 border-gray-300 rounded-lg"
        width={3840}
        height={100}
      />
    </div>
  );
};

export default function Hero() {
  usePageView();
  return (
    <div>
      <section className="py-16 md:py-24 px-4">
        <div className="flex flex-col items-center gap-8 text-center">
          <h1 className="text-4xl md:text-7xl font-bold tracking-tight max-w-[54rem] text-gray-900">
          Match People Who Work Better Together
          </h1>

          <p className="text-lg md:text-xl text-gray-300 max-w-[42rem] leading-relaxed">
          We take the guesswork out of pairing & grouping people together. From roommates to student teams, mentors and work groups, our AI helps you create the perfect team.
          </p>
          <p className="text-lg md:text-xl text-gray-300 max-w-[42rem] leading-relaxed">
          Perfect for co-living spaces, educational institutions, and corporate environments.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 mt-4">
            <Link href="/auth/login">
              <Button
                size="lg"
                className="bg-black hover:bg-gray-800 text-white transition-colors"
              >
                Get Started Free
                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </Link>

            <Link href="/howitworks">
              <Button
                variant="outline"
                size="lg"
                className="text-gray-900 hover:bg-gray-800 border-gray-300"
              >
                How It Works                
              </Button>
            </Link>
          </div>
          <span className="text-gray-200 max-w-2xl text-bold">No credit card needed.</span>
        </div>
      </section>
      <HowItWorks />
      <HeroScreenshot />
    </div>
  );
}
