import createLucideIcon from '../createLucideIcon';

/**
 * @component @name UserCog2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxOCIgY3k9IjE1IiByPSIzIiAvPgogIDxjaXJjbGUgY3g9IjgiIGN5PSI5IiByPSI0IiAvPgogIDxwYXRoIGQ9Ik0xMC41IDEzLjVBNiA2IDAgMCAwIDIgMTkiIC8+CiAgPHBhdGggZD0ibTIxLjcgMTYuNC0uOS0uMyIgLz4KICA8cGF0aCBkPSJtMTUuMiAxMy45LS45LS4zIiAvPgogIDxwYXRoIGQ9Im0xNi42IDE4LjcuMy0uOSIgLz4KICA8cGF0aCBkPSJtMTkuMSAxMi4yLjMtLjkiIC8+CiAgPHBhdGggZD0ibTE5LjYgMTguNy0uNC0xIiAvPgogIDxwYXRoIGQ9Im0xNi44IDEyLjMtLjQtMSIgLz4KICA8cGF0aCBkPSJtMTQuMyAxNi42IDEtLjQiIC8+CiAgPHBhdGggZD0ibTIwLjcgMTMuOCAxLS40IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/user-cog-2
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const UserCog2 = createLucideIcon('UserCog2', [
  ['circle', { cx: '18', cy: '15', r: '3', key: 'gjjjvw' }],
  ['circle', { cx: '8', cy: '9', r: '4', key: '143rtg' }],
  ['path', { d: 'M10.5 13.5A6 6 0 0 0 2 19', key: 'ih5ja7' }],
  ['path', { d: 'm21.7 16.4-.9-.3', key: '12j9ji' }],
  ['path', { d: 'm15.2 13.9-.9-.3', key: '1fdjdi' }],
  ['path', { d: 'm16.6 18.7.3-.9', key: 'heedtr' }],
  ['path', { d: 'm19.1 12.2.3-.9', key: '1af3ki' }],
  ['path', { d: 'm19.6 18.7-.4-1', key: '1x9vze' }],
  ['path', { d: 'm16.8 12.3-.4-1', key: 'vqeiwj' }],
  ['path', { d: 'm14.3 16.6 1-.4', key: '1qlj63' }],
  ['path', { d: 'm20.7 13.8 1-.4', key: '1v5t8k' }],
]);

export default UserCog2;
