import React from 'react';
import { ArrowRight, FileText, Users, BarChart3 } from 'lucide-react';

const HowItWorks = () => {
  const steps = [
    {
      icon: FileText,
      title: "Create Project & Questions",
      description: "Select or create questions that capture what matters most for your grouping needs.",
      color: "bg-blue-500"
    },
    {
      icon: Users,
      title: "Collect Responses",
      description: "Share the questions with participants and collect responses securely. No account creation required.",
      color: "bg-green-500"
    },
    {
      icon: BarChart3,
      title: "Generate & Compare Matches",
      description: "View responses and compare different grouping scenarios to find the best matches for your participants.",
      color: "bg-purple-500"
    }
  ];

  return (
    <div className="w-full max-w-6xl mx-auto">
      <div className="text-center mb-16">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-200">
          How Easy Pairing Works
        </h2>
        <p className="mt-4 text-lg text-gray-300">
          Three simple steps to create perfectly matched groups
        </p>
      </div>

      <div className="relative">
        {/* Connection Lines */}
        <div className="hidden md:block absolute top-1/2 left-0 w-full h-0.5 bg-gray-200 -translate-y-1/2 z-0" />
        
        <div className="relative z-10 grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
          {steps.map((step, index) => (
            <div key={index}
className="relative group">
              {/* Step Card */}
              <div className="bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow">
                {/* Icon Circle */}
                <div className={`${step.color} w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6 text-white shadow-lg group-hover:scale-110 transition-transform`}>
                  <step.icon size={32} />
                </div>

                {/* Content */}
                <h3 className="text-xl font-semibold text-gray-900 text-center mb-3">
                  {step.title}
                </h3>
                <p className="text-gray-600 text-center">
                  {step.description}
                </p>

                {/* Arrow for non-last items */}
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-6 text-gray-400 -translate-y-1/2">
                    <ArrowRight size={24} />
                  </div>
                )}
              </div>

              {/* Step Number */}
              <div className="absolute -top-4 -right-4 w-8 h-8 rounded-full bg-gray-900 text-white flex items-center justify-center text-sm font-bold">
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;